<template>
  <div class="home_cont">
    <div class="header">
      <!-- logo部分 -->
      <div class="logo">
        <div class="logo_img">
          <!-- <img src="../assets/logo2.jpg" alt=""> -->
        </div>
        <h2>大屏后台管理系统</h2>
      </div>
      <div class="nav">
        <!-- 头像 -->
        <div class="nav_logo">
          <span v-if="userAvatar === null"></span>
          <img v-else :src="userAvatar" alt="">
          <h2>{{userName}}</h2>
        </div>
        <!-- 下拉菜单 -->
        <div class="nav_select">
          <el-dropdown trigger="click" @command="handleCommand">
            <span class="el-dropdown-link">
              <i class="el-icon-caret-bottom"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-s-finance" command="sign_out">安全退出</el-dropdown-item>
              <el-dropdown-item icon="el-icon-user-solid" command="reset">重置密码</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!-- 页面主体区域 -->
    <div class="main_cont">
      <!-- 导航菜单 -->
      <div class="menu">
        <el-menu :unique-opened='true' router :default-active='activePaths'
          background-color="#324057" text-color="#fff" active-text-color="#169bd5" @select="isToken">
          <!-- 一级菜单 -->
            <template v-for="(item1, index1) in menuList">
              <el-submenu :index="index1 + ''" :key="index1" v-if="item1.children">
                  <!-- 一级菜单的模板区域 -->
                <template slot="title">
                    <!-- 图标 -->
                  <!-- <i :class="iconList[item.id]"></i> -->
                  <i class="el-icon-menu"></i>
                  <!-- 文本 -->
                  <span>{{item1.authName}}</span>
                </template>
                <!-- 二级菜单 -->
                <el-menu-item v-for="(item2, index2) in item1.children" :index="'/' + item2.path" :key="index2" @click="activePath('/' + item2.path)">
                  <template slot="title">
                      <!-- 文本 -->
                      <span>{{item2.authName}}</span>
                  </template>
                </el-menu-item>
              </el-submenu>
              <el-menu-item  v-else :index="'/' + item1.path" :key="index1" @click="activePath('/' + item1.path)">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <!-- 文本 -->
                  <span>{{item1.authName}}</span>
                </template>
              </el-menu-item>
            </template>
          </el-menu>
      </div>
      <!-- 右侧内容主体 -->
      <div class="main">
        <!-- 路由占位符 -->
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      userName: '',
      userAvatar: '',
      // 选择的第几个菜单
      activePaths: '',
      // 菜单栏的数据
      menuList: [
        {
          id: 8,
          authName: '集团管理',
          path: 'groupManager',
          children: [
            {
              authName: '项目总览',
              path: 'groupManager'
            }
          ]
        },
        {
          id: 2,
          authName: '海星医药健康创新园A区',
          path: 'overview',
          children: [
            {
              authName: '园区总览',
              path: 'overview'
            },
            {
              authName: '园区招商',
              path: 'attract'
            },
            {
              authName: '园区产业',
              path: 'industry'
            }
          ]
        },
        {
          id: 3,
          authName: '融汇管理',
          path: 'investment',
          children: [
            {
              authName: '园区招商',
              path: 'investment'
            }
          ]
        },
        {
          id: 4,
          authName: '中关村国际创新大厦',
          path: 'innovateOverview',
          children: [
            {
              authName: '园区总览',
              path: 'innovateOverview'
            },
            {
              authName: '园区招商',
              path: 'innovateInvestment'
            },
            {
              authName: '园区产业',
              path: 'zgc_company'
            }
          ]
        },
        {
          id: 6,
          authName: '海星医药健康创新园B区',
          path: 'overviewItem',
          children: [
            {
              authName: '项目总览',
              path: 'overviewItem'
            }
          ]
        },
        {
          id: 27,
          authName: '馨雅大厦',
          path: 'xinyaOverview'
        },
        {
          id: 29,
          authName: '北京卫星制造厂',
          path: 'bjwxzzcOverview'
        },
        {
          id: 30,
          authName: '十号院',
          path: 'shihaoyuanOverview'
        },
        {
          id: 39,
          authName: '未来科技大厦',
          path: 'wlkjdsOverview'
        },
        {
          id: 41,
          authName: '天津·旺海国际',
          path: 'tjxmOverview'
        },
        {
          id: 43,
          authName: '北大生物城',
          path: 'bdswcOverview'
        },
        {
          id: 45,
          authName: '上庄国际医谷',
          path: 'szgjygOverview'
        },
        {
          id: 9,
          authName: '大行基业',
          path: 'dahang',
          children: [
            {
              authName: '总览',
              path: 'dahang'
            }
          ]
        },
        {
          id: 10,
          authName: '海星医药健康创新园C区',
          path: 'haixing',
          children: [
            {
              authName: '总览',
              path: 'haixing'
            }
          ]
        },
        {
          id: 11,
          authName: '其他资产',
          path: 'otherAssets',
          children: [
            {
              authName: '总览',
              path: 'otherAssets'
            }
          ]
        },
        {
          id: 5,
          authName: '园区企业管理',
          path: 'park',
          children: [
            {
              authName: '企业列表',
              path: 'park'
            }
          ]
        },
        {
          id: 7,
          authName: '系统管理',
          path: 'employeeList',
          children: [
            {
              authName: '员工列表',
              path: 'employeeList'
            }
          ]
        }
      ]
    }
  },
  async created () {
    this.activePaths = window.sessionStorage.getItem('activePath')
    const { data: res } = await this.$http.get('get_token')
    this.userName = res.data.name
    this.userAvatar = res.data.avatar
    this.$store.commit('setPowerArr', res.data.permissions)
    const dataArr = []
    this.menuList.forEach(item => {
      this.$store.state.powerArr.forEach(item2 => {
        if (item.authName === item2.name && item2.id !== 14) {
          dataArr.push(item)
        }
      })
    })
    this.menuList = dataArr
    if (this.activePaths) {
      this.$router.push(`${this.activePaths}`)
    } else {
      this.$router.push(`/${dataArr[0].path}`)
    }
  },
  methods: {
    activePath (path) {
      window.sessionStorage.setItem('activePath', path)
      this.activePaths = path
    },
    // 退出登录和重置密码
    handleCommand (command) {
      if (command === 'sign_out') {
        window.sessionStorage.removeItem('token')
        window.sessionStorage.removeItem('activePath')
        this.$message({ message: '安全退出成功', type: 'success' })
        this.$router.push('/logincode')
      } else if (command === 'reset') {
        this.$router.push('/updated')
      }
    },
    async isToken () {
      try {
        const token = window.sessionStorage.getItem('token')
        await this.$http.get('get_token', { token })
      } catch (err) {
        this.$message({ message: '请重新登录', type: 'error' })
        this.$router.push('/logincode')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.home_cont{
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #324057;
    padding: 1rem;
    .logo{
      display: flex;
      align-items: center;
      .logo_img{
        img{
          width: 1.5rem;
          border-radius: 25px;
          background-color: #fff;
        }
      }
      h2{
        font-size: 1rem;
        color: #fff;
        // padding-left: 0.5rem;
        margin-top: -0.25rem;
      }
    }
    .nav{
      width: 5.2%;
      position: relative;
      cursor: pointer;
      .nav_logo{
        position: absolute;
        top: 0;
        transform: translateY(-50%);
        right: 20px;
        display: flex;
        img{
          width: 1.5rem;
          border-radius: 25px;
          background-color: #fff;
          margin-right: 5px;
        }
        h2{
          color: #fff;
          font-size: 1rem;
        }
      }
      .nav_select{
        position: absolute;
        top: 0;
        transform: translateY(-60%);
        right: 0;
        .el-dropdown{
          span{
            padding: 8px 0 8px 83px;
          }
          .el-icon-caret-bottom{
            color: #fff;
          }
        }
      }
    }
  }
  .main_cont{
    display: flex;
    justify-content: space-between;
    .menu{
      width: 14%;
      height: calc(100vh - 60px);
      background-color: #324057;
      .el-submenu .el-menu-item{
        min-width: 100%;
      }
      .el-menu{
        border-right: none;
      }
    }
    .main{
      width: 90%;
      background-color: #f5f7fa;
    }
  }
}
</style>
